.table {
    border-collapse: collapse;
    font-size: 16px;
    overflow: hidden;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 100%;
}

.table thead tr {
    background-color: #014282;
    color: #fff;
    text-align: center;
}

.table th,
.table td {
    padding: 12px 15px;
    text-align: center;
}

.table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.table tbody tr:last-of-type {
    border-bottom: 2px solid #014282;
}

.button-table {
    width: 100%;
    text-align: center;
    background-color: #014282;
    color: #fff;
    padding: 6px;
    border-color: transparent;
    box-shadow: 0 7px 10px 0 rgba(115, 115, 115, 1);
    border-radius: 50px;
    font-size: 14px;
    transition: all .3s;
}

.button-table.none {
    background-color: transparent;
    border: 1px solid #000;
    color: #000;
    box-shadow: 0 0 0 0;
}

.button-table:hover {
    cursor: pointer;
}

.button-table:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(115, 115, 115, 1);
    -moz-box-shadow: 0 0 0 0 rgba(115, 115, 115, 1);
    box-shadow: 0 0 0 0 rgba(115, 115, 115, 1);
}

.signature {
    border: 1px solid black;
}
