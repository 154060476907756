body .css-18aleyd-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 5px !important;
}

.image {
    width: 500px;
    border-radius: 30px;
}

.button {
    background-color: #014282;
    color: #fff;
    width: 100%;
    padding: 10px;
    border-color: transparent;
    -webkit-box-shadow: 0px 7px 10px 0px rgba(115, 115, 115, 1);
    -moz-box-shadow: 0px 7px 10px 0px rgba(115, 115, 115, 1);
    box-shadow: 0px 7px 10px 0px rgba(115, 115, 115, 1);
    border-radius: 50px;
    font-size: 18px;
    transition: all .3s;
}

.button__disabled {
    background-color: grey;
    color: #fff;
    width: 100%;
    padding: 10px;
    border-color: transparent;
    -webkit-box-shadow: 0px 7px 10px 0px rgba(115, 115, 115, 1);
    -moz-box-shadow: 0px 7px 10px 0px rgba(115, 115, 115, 1);
    box-shadow: 0px 7px 10px 0px rgba(115, 115, 115, 1);
    border-radius: 50px;
    font-size: 18px;
    transition: all .3s;
}

.button:hover {
    cursor: pointer;
}

.button:focus {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(115, 115, 115, 1);
    -moz-box-shadow: 0px 0px 0px 0px rgba(115, 115, 115, 1);
    box-shadow: 0px 0px 0px 0px rgba(115, 115, 115, 1);
}
